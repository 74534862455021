import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import Groups from "../constants/Groups";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";
import rem from "../utils/rem";
import { getMatchupSlug } from "../utils/game";
import IncidentForm from "../components/IncidentForm";
import GameIncidentUpdatesTable from "../components/GameIncidentUpdatesTable";
import IncidentMetadata from "../components/IncidentMetadata";
import Editable from "../components/Editable";
import VerifyDeleteModal from "../components/VerifyDeleteModal";
import BaseChevron from "../components/icons/Chevron";
import Trash from "../components/icons/Trash";
import { useAuth } from "../hooks/useAuth";

const Wrapper = styled.div`
  margin: ${rem(30)};
  width: 1330px;
`;

const IncidentNameWrapper = styled.div`
  padding: ${rem(20)} 0;
  font-size: ${rem(20)};
`;

const GameSlug = styled.div`
  font-size: ${rem(24)};
  font-weight: 500;
`;

const Chevron = BaseChevron``;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexItem = styled.div`
  flex: ${(props) => props.flex};
  align-self: flex-end;
`;

const FormExpander = styled.div`
  cursor: pointer;
  margin: 20px 0;
`;

const SmallText = styled.span`
  font-size: ${rem(13)};
  color: #333;
`;

const DeleteIcon = styled.span`
  float: right;
  color: red;
  cursor: pointer;
`;

const ViewIncident = ({ rootStore, match }) => {
  const [showForm, setShowForm] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { username, isAuthenticated, isAdmin } = useAuth();
  const { veracityStore: store } = rootStore;
  const hasIncidentEdit = isAdmin;

  useEffect(() => {
    store.fetchIncident(match.params.id);
  }, [store, match.params.id]);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const deleteIncident = () => {
    store.deleteIncident(match.params.id);
  };

  const incident = store.singleIncident;
  const canEdit = isAuthenticated && hasIncidentEdit;
  const timeZone = process.env.REACT_APP_TIMEZONE;

  if (_.isEmpty(incident)) {
    return null;
  }

  return (
    <Wrapper>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        ariaHideApp={false}
        className="deleteModal"
      >
        <VerifyDeleteModal
          message="Are you sure you want to remove this incident?"
          handleDelete={deleteIncident}
          toggleModal={toggleModal}
        />
      </Modal>
      <h1>
        Data Quality Report - Incident - #{incident.id}
        {canEdit && (
          <DeleteIcon onClick={toggleModal}>
            <Trash />
          </DeleteIcon>
        )}
      </h1>
      <GameSlug>
        {incident.systemWide
          ? "System Wide Incident"
          : `${getMatchupSlug(incident.game)}`}{" "}
        - {moment(incident.incidentDate).format("LL")}
      </GameSlug>
      <IncidentNameWrapper>
        <Editable
          disabled={!canEdit}
          handleSave={(value) => store.updateSingleIncidentName(value)}
        >
          {incident.name}
        </Editable>
      </IncidentNameWrapper>
      <IncidentMetadata incident={incident} />
      <hr />
      <FlexContainer>
        <FlexItem>
          <h2>
            Incident History <SmallText>(all times {timeZone})</SmallText>
          </h2>
        </FlexItem>
        {canEdit && (
          <FlexItem>
            <FormExpander onClick={() => setShowForm(!showForm)}>
              <Chevron direction={showForm ? "up" : "down"} /> Update Incident
            </FormExpander>
          </FlexItem>
        )}
      </FlexContainer>
      {showForm && (
        <IncidentForm
          onSubmit={store.createIncidentUpdate}
          afterSubmit={() =>
            store.fetchIncident(incident.id).then(() => {
              setShowForm(false);
            })
          }
          isEditing
          previousIncident={incident}
          incidentType={incident.incidentType}
        />
      )}
      <GameIncidentUpdatesTable rootStore={rootStore} />
    </Wrapper>
  );
};

export default withRouter(ViewIncident);
