import { useOktaAuth } from "@okta/okta-react";
import { useCallback, useState, useEffect } from "react";
import Groups from "../constants/Groups";

export function useAuth() {
  const { oktaAuth, authState } = useOktaAuth();
  const isLoading = authState?.isLoading ?? true;
  const token = authState?.accessToken?.accessToken ?? "";

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (authState && authState.accessToken) {
      const fetchUserInfo = async () => {
        const user = await oktaAuth.getUser(authState.accessToken);
        setUserInfo(user);
      };
      fetchUserInfo();
    }
  }, [authState, oktaAuth]);

  const isAuthenticated = authState?.isAuthenticated ?? false;

  const username = userInfo?.email?.toLowerCase();
  const email = userInfo?.email?.toLowerCase();
  const oktaId = userInfo?.sub;
  const groups = userInfo?.groups;
  const isAdmin = groups?.includes(Groups.ADMIN);
  const isAdminOrSuperUser =
    groups?.includes(Groups.ADMIN) || groups?.includes(Groups.SUPER_USER);

  const isAuthorized = useCallback(
    (...groups) => {
      if (!isLoading && userInfo) {
        return true;
      }
    },
    [userInfo, isLoading],
  );

  return {
    isAuthenticated,
    username,
    email,
    oktaId,
    groups,
    token,
    isAdmin,
    isAdminOrSuperUser,
    isAuthorized,
  };
}
