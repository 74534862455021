import { useOktaAuth } from "@okta/okta-react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const Login = ({ rootStore }) => {
  const { oktaAuth } = useOktaAuth();
  const saasLogoURL = process.env.REACT_APP_SAAS_LOGO_URL;
  const loginWithOkta = async () => {
    await oktaAuth.signInWithRedirect({ originalUri: "/" });
  };

  const LoginPage = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `;

  const Logo = styled.img`
    width: 200px;
    margin-bottom: 20px;
  `;

  const OktaSignInButton = styled(Button)`
    width: 200px;
    color: #9e9e9e;
    font-weight: bold;
    text-decoration: none;
  `;

  return (
    <LoginPage>
      <Logo src={saasLogoURL} alt="Logo" />
      <OktaSignInButton variant="outlined" onClick={loginWithOkta}>
        Sign In with Okta
      </OktaSignInButton>
    </LoginPage>
  );
};

export default Login;
