import { OktaAuth } from "@okta/okta-auth-js";
import { RouteConstants } from "../constants/RouteConstants";

const onAuthRequired = ({ history }) => {
  history.push(RouteConstants.LOGIN);
};

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "email", "offline_access"],
  pkce: true,
  /**
   * https://github.com/okta/okta-auth-js/blob/master/docs/autoRenew-notice.md
   */
  tokenManager: {
    autoRenew: true,
  },
  services: {
    autoRenew: false,
    autoRemove: false,
  },
  onAuthRequired,
});

export default oktaAuth;
