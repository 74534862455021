import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SecureRoute, LoginCallback } from "@okta/okta-react";
import { ToastContainer } from "react-toastify";
import CreateNewIncident from "./containers/CreateNewIncident";
import ViewIncident from "./containers/ViewIncident";
import { RouteConstants } from "./constants/RouteConstants";
import Login from "./auth/Login.js";

const AppRoutes = ({ rootStore, isSaasOrgPresent }) => {
  return (
    <>
      <ToastContainer />
      {rootStore.authStore.pages.map((page) => {
        if (page.subRoutes) {
          return page.subRoutes.map((subPage) => {
            return (
              <SecureRoute
                key={subPage.path}
                exact={subPage.isExact}
                path={subPage.path}
                component={(props) => (
                  <subPage.component {...props} rootStore={rootStore} />
                )}
              />
            );
          });
        } else {
          return (
            <SecureRoute
              key={page.path}
              exact={page.isExact}
              path={page.path}
              component={(props) => (
                <page.component {...props} rootStore={rootStore} />
              )}
            />
          );
        }
      })}
      <Route path={RouteConstants.LOGIN} component={Login} />
      <Route path="/login/callback" component={LoginCallback} />
      <Switch>
        <SecureRoute
          exact
          path={RouteConstants.INCIDENTS_NEW}
          component={(props) => (
            <CreateNewIncident {...props} rootStore={rootStore} />
          )}
        />
        <SecureRoute
          path={RouteConstants.INCIDENTS}
          component={(props) => (
            <ViewIncident {...props} rootStore={rootStore} />
          )}
        />
        {isSaasOrgPresent && (
          <Redirect
            exact
            from={RouteConstants.BASE}
            to={RouteConstants.SAAS_TRACKING}
          />
        )}
      </Switch>
    </>
  );
};

export default AppRoutes;
