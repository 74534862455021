import { Provider } from "mobx-react";
import React from "react";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Navbar from "./containers/Navbar";
import RootStore from "./stores/rootStore";
import { RouteConstants } from "./constants/RouteConstants";
import { Security } from "@okta/okta-react";
import AppRoutes from "./AppRoutes";
import oktaAuth from "./auth/oktaAuth";
import { useEffect } from "react";

const rootStore = new RootStore();
const theme = {
  lightGray: "rgb(240, 240, 240)",
  darkRed: "rgb(132, 0, 7)",
  brown: "rgb(130, 85, 8)",
  green: "rgb(17, 132, 44)",
  primary: "palevioletred",
};

const updateDocumentTitle = (isSaasOrgPresent) => {
  useEffect(() => {
    const saasOrg = process.env.REACT_APP_SASS_ORG;
    document.title = `${saasOrg.toUpperCase()}  System Status`;
  }, [isSaasOrgPresent]);
};

const App = () => {
  const isSaasOrgPresent = !!process.env.REACT_APP_SASS_ORG;
  updateDocumentTitle(isSaasOrgPresent);
  return (
    <Provider rootStore={rootStore}>
      <Router history={rootStore.history}>
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={() => rootStore.history.push(RouteConstants.LOGIN)}
          restoreOriginalUri={async (_oktaAuth, originalUri) => {
            rootStore.history.replace(originalUri || RouteConstants.BASE);
          }}
        >
          <ThemeProvider theme={theme}>
            <div>
              <Navbar rootStore={rootStore} />
              <AppRoutes
                rootStore={rootStore}
                isSaasOrgPresent={isSaasOrgPresent}
              />
            </div>
          </ThemeProvider>
        </Security>
      </Router>
    </Provider>
  );
};

export default App;
