import _ from "lodash";
import { computed, action, extendObservable } from "mobx";
import Groups from "../constants/Groups";
import { useAuth } from "../hooks/useAuth";
import { RouteConstants } from "../constants/RouteConstants";
import MLBTrackingAccuracyReportContainer from "../containers/MLBTrackingAccuracyReportContainer";
import MILBTrackingAccuracyReportContainer from "../containers/MILBTrackingAccuracyReportContainer";
import NonMLBTrackingAccuracyReportContainer from "../containers/NonMLBTrackingAccuracyReportContainer";
import LatestIncidentsContainer from "../containers/LatestIncidentsContainer";
import AAATrackingAccuracyReportContainer from "../containers/AAATrackingAccuracyReportContainer";
import AATrackingAccuracyReportContainer from "../containers/AATrackingAccuracyReportContainer";
import HighATrackingAccuracyReportContainer from "../containers/HighATrackingAccuracyReportContainer";
import ATrackingAccuracyReportContainer from "../containers/ATrackingAccuracyReportContainer";
import RookieTrackingAccuracyReportContainer from "../containers/RookieTrackingAccuracyReportContainer";
import AmateurTrackingAccuracyReportContainer from "../containers/AmateurTrackingAccuracyReportContainer";
import WinterTrackingAccuracyReportContainer from "../containers/WinterTrackingAccuracyReportContainer";
import InternationalIndependentTrackingAccuracyReportContainer from "../containers/InternationalIndependentTrackingAccuracyReportContainer";
import SaasTrackingAccuracyReportContainer from "../containers/saas/SaasTrackingAccuracyReportContainer";
import SaasLatestIncidentsContainer from "../containers/saas/SaasLatestIncidentsContainer";

class AuthStore {
  constructor(routerStore, auth) {
    this.routerStore = routerStore;
    this.auth = auth;

    extendObservable(this, {
      isAuthenticated: false,
      isGettingUser: false,
      error: {
        isVisible: false,
        message: "",
      },
      user: null,
      username: computed(() => (this.user ? this.user.email : null)),
      groups: computed(() => (this.user ? this.user.groups : null)),
      hasIncidentEdit: computed(
        () => true,
        // () => this.user && _.includes(this.user.groups, Groups.ADMIN),
      ),
      isAdmin: computed(
        () => this.user && _.includes(this.user.groups, Groups.ADMIN),
      ),
      isAdminOrSuperUser: computed(
        () =>
          this.user &&
          (_.includes(this.user.groups, Groups.ADMIN) ||
            _.includes(this.user.groups, Groups.SUPER_USER)),
      ),
      logout: action(async () => {
        this.isAuthenticated = false;
        this.auth.signOut(RouteConstants.LOGIN);
      }),
      pages: computed(() => {
        const org = process.env.REACT_APP_SASS_ORG;
        if (!org) {
          return [
            {
              path: RouteConstants.BASE,
              label: "MLB",
              isProtected: true,
              isActive: this.routerStore.isMLBTrackingAccuracyReportTab,
              component: MLBTrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.NON_MLB_STATCAST,
              label: "Non-MLB Statcast",
              isProtected: true,
              isActive: this.routerStore.isNonMLBTrackingAccuracyReportTab,
              component: NonMLBTrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.AAA_TRACKING,
              label: "AAA",
              isProtected: true,
              isActive: this.routerStore.isAAATrackingAccuracyReportTab,
              component: AAATrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.AA_TRACKING,
              label: "AA",
              isProtected: true,
              isActive: this.routerStore.isAATrackingAccuracyReportTab,
              component: AATrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.HighA_TRACKING,
              label: "High-A",
              isProtected: true,
              isActive: this.routerStore.isHighATrackingAccuracyReportTab,
              component: HighATrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.A_TRACKING,
              label: "A",
              isProtected: true,
              isActive: this.routerStore.isATrackingAccuracyReportTab,
              component: ATrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.ROOKIE_TRACKING,
              label: "Rookie",
              isProtected: true,
              isActive: this.routerStore.isRookieTrackingAccuracyReportTab,
              component: RookieTrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.AMATEUR_TRACKING,
              label: "Amateur",
              isProtected: true,
              isActive: this.routerStore.isAmateurTrackingAccuracyReportTab,
              component: AmateurTrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.WINTER_TRACKING,
              label: "Winter",
              isProtected: true,
              isActive: this.routerStore.isWinterTrackingAccuracyReportTab,
              component: WinterTrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.INDEPENDENT_INTERNATIONAL_TRACKING,
              label: "Int & Indy",
              isProtected: true,
              isActive:
                this.routerStore
                  .isInternationalIndependentTrackingAccuracyReportTab,
              component:
                InternationalIndependentTrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.MILB_TRACKING,
              label: "MiLB (Video Only)",
              isProtected: true,
              isActive: this.routerStore.isMILBTrackingAccuracyReportTab,
              component: MILBTrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.LATEST_INCIDENTS,
              label: "Latest Incidents",
              isProtected: true,
              isActive: this.routerStore.isLatestIncidentsTab,
              component: LatestIncidentsContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
          ];
        } else {
          return [
            {
              path: RouteConstants.SAAS_TRACKING,
              label: org.toUpperCase(),
              isProtected: true,
              isActive: this.routerStore.isSaasTrackingAccuracyReportTab,
              component: SaasTrackingAccuracyReportContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
            {
              path: RouteConstants.LATEST_INCIDENTS,
              label: "Latest Incidents",
              isProtected: true,
              isActive: this.routerStore.isLatestIncidentsTab,
              component: SaasLatestIncidentsContainer,
              isExact: true,
              isHiddenOnNav: false,
            },
          ];
        }
      }),
      // updateUserInfo: action(async () => {
      //   console.log("Updating UserInfo");
      //   try {
      //     this.isGettingUser = true;
      //     const isAuthenticated = await this.auth.isAuthenticated();
      //     this.isAuthenticated = isAuthenticated;
      //     console.log("isAuthenticated:", isAuthenticated);
      //     if (isAuthenticated) {
      //       const user = await this.auth.getUser();
      //       this.user = user;
      //       return user;
      //       console.log("User from authStore:", user);
      //     } else {
      //       this.logout();
      //     }
      //   } catch (e) {
      //     console.error("Failed to get user", e);
      //     window.localStorage.clear();
      //   }
      //   this.isGettingUser = false;
      // }),
      getUser: action(async () => {
        console.log("Getting user");
        try {
          this.isGettingUser = true;
          const isAuthenticated = await this.auth.isAuthenticated();
          this.isAuthenticated = isAuthenticated;
          console.log("isAuthenticated:", isAuthenticated);
          if (isAuthenticated) {
            const user = await this.auth.getUser();
            this.user = user;
            return user;
          } else {
            this.logout();
          }
        } catch (e) {
          console.error("Failed to get user", e);
          window.localStorage.clear();
        }
        this.isGettingUser = false;
      }),
      onError: action((err) => {
        console.log("error logging in", err);
      }),
    });
  }
}

export default AuthStore;
