import axios from "axios";
import oktaAuth from "../auth/oktaAuth";

const authClient = axios.create();

authClient.interceptors.request.use(
  async (config) => {
    /**
     * The tokenManager takes care of refreshing the token.
     * If its not expired it will get the token from localStorage.
     * Otherwise it will get a new one from okta.
     */
    try {
      let tokenManagerResponse = await oktaAuth.tokenManager.get("accessToken");
      if (tokenManagerResponse) {
        const { accessToken } = tokenManagerResponse;
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        window.localStorage.clear();
      }
      return config;
    } catch (err) {
      //Something went wrong, clear out localStorage to make the user login again
      window.localStorage.clear();
    }
  },
  (error) => {
    return error;
  },
);

export default authClient;
